<template>
  <div>
    <BaseHeader :title="fanpage.fb_fanpage_title ? fanpage.fb_fanpage_title : 'Fanpage'" :navigation="navigation">
      <div class="d-flex">
        <ErrorIntegration :fanpage="fanpage" :type="typeErrorIntegration" />
        <BaseButton variant="outline-danger" @click="desFanpage">
          Desassociar Fanpage
        </BaseButton>
      </div>
    </BaseHeader>
    <div class="row col-12 w-100 content-info">
      <div class="col-3 d-flex pl-0">
        <div class="content-icon mr-3">
          <img :src="imgCalendar" alt="Calendario" />
        </div>
        <div>
          <span class="title">Data da associação</span>
          <br />
          <span class="text-secondary">{{ getCreatedAt }}</span>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-center">
        <div class="content-icon mr-3">
          <img :src="imgLeadCap" alt="Lead capturado" />
        </div>
        <div>
          <span class="title">Último lead capturado</span>
          <br />
          <span class="text-secondary">{{ getLastCatch }}</span>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-center">
        <div class="content-icon mr-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#141414" viewBox="0 0 256 256"><path d="M240.49,63.51a12,12,0,0,0-17,0L192,95,161,64l31.52-31.51a12,12,0,0,0-17-17L144,47,120.49,23.51a12,12,0,1,0-17,17L107,44,56.89,94.14a44,44,0,0,0,0,62.23l12.88,12.88L23.51,215.51a12,12,0,0,0,17,17l46.26-46.26,12.88,12.88a44,44,0,0,0,62.23,0L212,149l3.51,3.52a12,12,0,0,0,17-17L209,112l31.52-31.51A12,12,0,0,0,240.49,63.51Zm-95.6,118.63a20,20,0,0,1-28.29,0L73.86,139.4a20,20,0,0,1,0-28.29L124,61l71,71Z"></path></svg>
        </div>
        <div>
          <span class="title">Total de integrações</span>
          <br />
          <span class="text-secondary">{{ fanpage.integrations }}</span>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-end">
        <div class="content-icon mr-3">
          <img :src="imgPeople" alt="Calendario" />
        </div>
        <div>
          <span class="title">Leads capturados</span>
          <br />
          <span class="text-secondary">{{ this.fanpage.total_leads }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ErrorIntegration from "./ErrorIntegration.vue";
import BaseHeader from "@/components/BaseHeader";
import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();
import Vue from "vue";

export default {
  name: "HeaderFanpage",
  props: {
    fanpage: {
      type: Object,
      required: true,
    },
    IdModalIntegration: {
      required: true,
    },
  },
  components: {
    ErrorIntegration,
    BaseHeader,
  },

  data: () => ({
    imgCalendar: require("@/assets/icons/calendar.svg"),
    imgLeadCap: require("@/assets/icons/lightning.svg"),
    imgPeople: require("@/assets/icons/people.svg"),
    timeAssociation: "",
  }),
  computed: {
    navigation: function () {
      return [
      { link: "Aplicativos", to: "/dynamicRoute/apps" },
      { link: "LeadAds", to: "/dynamicRoute/apps/lead-ads" },
      { link: this.fanpage.fb_fanpage_title, to: this.$route.fullPath },
    ]
    },
    getCreatedAt: function () {
      return moment(this.fanpage.created_at).format("DD/MM/YYYY HH:MM");
    },
    getUpdated: function () {
      return moment(this.fanpage.updated_at).format("DD/MM/YYYY HH:MM");
    },
    getLastCatch: function () {
      return this.fanpage.last_catch ?? "Aguardando leads";
    },
    fbFanpageId: function () {
      return this.fanpage ?? {};
    },
    showButtonNewIntegration: function () {
      if (
        this.fanpage.status !== "token_error" &&
        this.fanpage.status !== "subscription_error"
      ) {
        return true;
      }
      return false;
    },
    typeErrorIntegration: function () {
      switch (this.fanpage.status) {
        case "token_error":
          return "token";
        case "subscription_error":
          return "subscription";
      }
      return false;
    },
  },
  methods: {
    desFanpage(){
      Vue.swal({
          title: `Desassociar fanpage`,
          text: `Deseja continuar com a desassociação dessa fanpage?`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: "Desassociar",
          cancelButtonText: "Cancelar",
          customClass: "sweet-container",
          confirmButtonClass: "button button-danger mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            const id = this.$route.params.id;
            let deleted = await LeadAdApiService.destroy('/fanpage/'+id+'/disassociate')
            if (!deleted.error) {
              this.$grToast.toast("Fanpage desassociada com sucesso", {
                title: "Fanpage",
                variant: "success",
                autoHideDelay: 3000,
                appendToast: true,
              });
              setTimeout(() => {
                this.$router.push(`/${this.$store.getters.tenantSubdomain}/apps/lead-ads`);
              }, 2000);
            } else {
              this.$grToast.toast(deleted.error, {
                title: "Fanpage",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            }
          }
        });
    }
  }
};
</script>

<style scoped>
.text-secondary {
  font-size: 14px;
  line-height: 17px;
}

.total-integration {
  margin-top: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #81858e;
}
.content-info {
  margin: 41px 0 0 !important;
  border: 1px solid var(--greenn);
  background-color: var(--greenn2);
  padding: 20px;
  border-radius: 10px;
}
.content-icon {
  width: 40px;
  height: 40px;
  background: var(--greenn2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  width: 13px;
  height: 14.33px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  color: var(--gray01);
}

.btn-integration,
.btn-open-modal-dissociation-fanpage {
  height: 50px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  border: 0px;
}
.btn-open-modal-dissociation-fanpage:active {
  border: 0px;
}
.btn-integration {
  width: 216px;
  background-color: var(--background-color-white-light);
  margin-right: 25px;
}

.btn-open-modal-dissociation-fanpage {
  width: 209px;
  color: #ffffff;
  border: 0px;
  background-color: var(--red);
}

.btn-open-modal-dissociation-fanpage:hover {
  color: #ffffff;
  border: 0px;
}
</style>
