<template>
<div>
  <Content>
      <Fanpage/>
  </Content>
  </div>

</template>
<script>
import BaseView from '@/template/BaseView.vue';
import Content from "@/components/LeadAd/Content.vue";
import Fanpage from "@/components/LeadAd/Fanpage.vue";


export default {
  components: {
    BaseView,
    Content,
    Fanpage
  },
};
</script>