<template>
  <div class="container">
    <HeaderFanpage 
      :fanpage="fanpage" 
      :IdModalIntegration="IdModalIntegration"/>
    <ModalIntegration 
    :idModal="IdModalIntegration" 
    :fanpageName="fanpage.fb_fanpage_title" 
    :edit="edit"
    @integration="integration" 
    @modalHide="modalIntegrationHide"
    />
    <div class="fanpage-integration-list">
      <div class="allSelectStyle">
        <b-collapse v-model="isOneSelected">
          <BaseButton :disabled="!isOneSelected" @click="removeIntegration" class="btn-open-modal-dissociation-fanpage mr-3"  variant="outline-danger" style="margin-right: 30px;">
            Remover Integração
          </BaseButton>
        </b-collapse>
        <BaseButton class="ml-2" v-b-modal.modal-new-integration-fanPage variant="primary" @click="$bvModal.show(IdModalIntegration)" v-if="showButtonNewIntegration">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="16" height="16" fill="#fff" viewBox="0 0 256 256"><path d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z"></path></svg> Nova integração
        </BaseButton>
      </div>
      <b-table :borderless="true" :items="items" :fields="fields" :fixed="false" show-empty>
        <template #empty="scope">
          <b-row class="justify-content-center style" v-if="!loadingTable">
            <p class="nao-encontrado">Nenhuma integração foi encontrada</p>
          </b-row>
          <div v-else class="w-100">
            <div v-for="n in 4" :key="n" class="shine mb-2 py-3 w-100">
            </div>
          </div>
        </template>
        <template v-slot:head(checkbox)>
          <b-form-checkbox class="input-position" style="top: 2px;" v-model="selectAll"
            :disabled="isAllSelected" @change="handleSelectAllChange()" name="selectedItems"></b-form-checkbox>
        </template>
        <template #cell(id)="row"> # {{ row.item.id }} </template>
        <template #cell(checkbox)="row">
          <b-form-checkbox class="input-position" v-model="selectedItems" :value="row.item.id"
            name="selectedItems"></b-form-checkbox>
        </template>
        <template #cell(integrations)="row">
          <div class="d-flex align-items-center">
            <span class="d-inline-block text-truncate" style="max-width: 150px" :title="row.item.integration">{{
              row.item.integration }}</span>
            <img :src="require('@/assets/icons/facebookBlack.svg')" alt="Facebook" />
            <img :src="require('@/assets/icons/lightningGreen.svg')" alt="Integração" />
            <img :src="require('@/assets/icons/gDigital.svg')" alt="Integração" />
            <span class="d-inline-block text-truncate" style="max-width: 150px" :title="row.item.form_g_digital">{{
              row.item.form_g_digital }}</span>
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="deleted-actions">
            <div class="blue-item" @click="editIntegration(row.item)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style="fill: inherit;" viewBox="0 0 256 256"><path d="M227.31,73.37,182.63,28.68a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H92.69A15.86,15.86,0,0,0,104,219.31L227.31,96a16,16,0,0,0,0-22.63ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.68,147.31,64l24-24L216,84.68Z"></path></svg>
            </div>
            <a :href="`/${$store.getters.user.user.tenant_subdomain}/apps/lead-ads/${idFanpage}/leadgen/` + row.item.fanpage_form_integration" title="Detalhes">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" style="stroke: inherit;" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
            </a>
          </div>
        </template>
      </b-table>
      <VFacebookLoginScope :app-id="getIdApp" />
      <Paginate
        v-if="getTotalRows && getTotalRows > 1"
        :totalPages="getTotalRows"
        :activePage="currentPage"
        @to-page="page"
      />
    </div>
  </div>
</template>

<script>
import LeadAdService from "@/services/resources/LeadAdService.js";
import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";
import FormLeadAdForm from "@/services/resources/LeadAdFormService.js";
import moment from "moment";
import HeaderFanpage from "./HeaderFanpage.vue";
import ModalIntegration from "./Modalntegration.vue";
import Paginate from "@/components/Paginate";

// import leadgenForIntegration from "@/services/resources/leadgenForIntegration.js";e

const LeadAdApiService = LeadAdService.build();
const LeadAdFormApiService = FormLeadAdForm.build();
// const leadgenForIntegrationService = leadgenForIntegration.build();

import { mapMutations } from "vuex";
import Vue from 'vue';
export default {
  name: "Fanpage",
  components: {
    HeaderFanpage,
    ModalIntegration,
    VFacebookLoginScope,
    Paginate,
  },
  data: () => ({
    IdModalIntegration: "modal-integration",
    fanpage: {},
    idFanpage: null,
    selectAll: false,
    selectOne: false,
    loadingTable: false,
    fields: [
      { key: "checkbox", label: "" },
      { key: "integrations", label: "Integração" },
      "Última captura",
      "Criado em",
      "Atualizado em",
      { key: "actions", label: "" },
    ],
    selectedItems: [],
    items:[],
    currentPage: 1,
    getTotalRows: 0,
    getPerPage: 0,
    numberPage: 0,
    edit: {},
    navigation: [
      { link: "Aplicativos", to: "/dynamicRoute/apps" },
      { link: "LeadAds", to: "/dynamicRoute/apps/lead-ads" },
    ],
  }),
  computed: {
    showButtonNewIntegration: function () {
      if (
        this.fanpage.status !== "token_error" &&
        this.fanpage.status !== "subscription_error"
      ) {
        return true;
      }
      return false;
    },
    getIdApp: () => process.env.FACEBOOK_APP_ID,

    isAllSelected() {
      if (this.selectedItems.length === this.items.length && this.items.length !== 0) {
        this.selectAll = true
      }else {
        this.selectAll = false
      }
    },
    isOneSelected: {
      get: function () {
        if(this.selectedItems.length == 0){
          return false
        }else{
          return true
        }
      },
      set: function (newValue) {
        // O setter foi adicionado para evitar um erro no console, uma vez que ele está sendo usado em uma v-model do b-collapse.
      }
    }
  },
  created: async function () {
    this.loadDataHeader();
  },
  methods: {
    ...mapMutations(["loading"]),
    async loadDataHeader() {
      this.loadingTable = true;
      const id = this.$route.params.id;
      this.idFanpage = id;
      try {
        const fanpage = await LeadAdApiService.read(id);
        this.fanpage = fanpage;
        await this.setDataItemTable();
      } catch (e) {
        this.$grToast.toast("Falha ao carregar dados da Fanpage.", {
          title: "Fanpage",
          variant: "danger",
          autoHideDelay: 3000,
          appendToast: true,
        });
      }
      this.loadingTable = false
    },
    openModalIntegration() {
      this.$bvModal.show(this.IdModalIntegration);
    },
    modalIntegrationHide() {
      this.edit = {};
    },
    editIntegration(data) {
      this.edit = data;
      this.$bvModal.show(this.IdModalIntegration);
    },
    setDataForPaginate(formsFanpage) {
      this.currentPage = formsFanpage.current_page;
      this.getTotalRows = formsFanpage.last_page;
      this.getPerPage = formsFanpage.per_page;
    },
    async getDateForm(page) {
      if (!page && !this.numberPage) {
        this.numberPage = 1;
      } else if (page !== undefined) {
        this.numberPage = page;
      }

      let formsFanpage = await LeadAdApiService.read(
        "fanpage/" + this.idFanpage + "/forms?page=" + this.numberPage
      );

      if (formsFanpage.error) {
        this.$grToast.toast("Falha ao carregar dados de formulários", {
          title: "Fanpage",
          variant: "danger",
          autoHideDelay: 3000,
          appendToast: true,
        });
      }
      return formsFanpage;
    },
    async integration(edit) {
      await this.setDataItemTable();
      if (!edit) {
        this.fanpage.integrations++;
      }
    },
    async setDataItemTable() {
      const formsFanpage = await this.getDateForm();
      this.setDataForPaginate(formsFanpage);
      this.formatData(formsFanpage);
    },
    formatData(formsFanpage) {
      this.items = [];
      for (let formFanpage of formsFanpage.data) {
        this.items.push({
          id: formFanpage.id,
          "Criado em": moment(formFanpage.created_at).format(
            "DD/MM/YYYY HH:MM"
          ),
          "Atualizado em": moment(formFanpage.updated_at).format(
            "DD/MM/YYYY HH:MM"
          ),
          integration: formFanpage.fb_form_title,
          "Última captura": formFanpage.last_catch ?? "Aguardando leads",
          form_g_digital: formFanpage.form_g_digital,
          form_g_digital_id: formFanpage.form_g_digital_id,
          fb_form_id: formFanpage.fb_form_id,
          fb_form_title: formFanpage.fb_form_title,
          fanpage_form_integration: formFanpage.fanpageFormIntegration.id,
        });
      }
    },
    async removeIntegration() {
      if(this.selectedItems.length != 0){
        Vue.swal({
        title: `Remover ${this.selectedItems.length > 1 ? 'integrações' : 'integração'}`,
        text: `Deseja realmente remover ${this.selectedItems.length > 1 ? ' as integrações' : 'a integração'}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
        this.loading(true);
            let deleted = await LeadAdFormApiService.createId({id:'delete' ,  forms_ids: this.selectedItems });
            this.selectedItems = []
            // let deleted = await LeadAdFormApiService.destroy({ id: item.id });
          this.loadDataHeader();
          if (!deleted.error) {
            this.$grToast.toast(`${this.selectedItems.length > 1 ? 'Integrações removidas' : 'Integração removida'} com sucesso.`, {
              title: "Fanpage",
              variant: "success",
              autoHideDelay: 3000,
              appendToast: true,
            });
            this.setDataItemTable();
            this.loading(false);
            return;
          }
          this.$grToast.toast(`Falha ao remover ${this.selectedItems.length > 1 ? 'as integrações' : 'a integração'}.`, {
            title: "Fanpage",
            variant: "danger",
            autoHideDelay: 3000,
            appendToast: true,
          });
          this.loading(false);
          
        }
      });
      }
    },
    async page(page) {
      this.loadingTable = true;
      const formsFanpage = await this.getDateForm(page);
      this.formatData(formsFanpage);
      this.loadingTable = false;
    },
    handleSelectAllChange() {
      if (this.selectAll) {
        this.selectedItems = this.items.map(item => item.id);
      } else {
        this.selectedItems = [];
      }
    },
  },

};
</script>

<style scoped lang="scss">
.deleted-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  .blue-item {
    border: 1px solid var(--bluee) !important;
    color: var(--bluee) !important;
    stroke: var(--bluee) !important;
    fill: var(--bluee) !important;
    &:hover {
      background-color: var(--bluee) !important;
    }
  }
  > * {
    height: 35px;
    padding: 0px 15px;
    border: 1px solid var(--greenn);
    background-color: transparent;
    color: var(--greenn);
    border-radius: 10px;
    line-height: 1;
    transition: .3s;
    stroke: var(--greenn);
    fill: var(--greenn);
    &:hover {
      background-color: var(--greenn);
      color: white !important;
      stroke: white !important;
      fill: white !important;
    }
  }
  > div, > a {
    cursor: pointer;
    width: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

img {
  padding: 5px;
}

.allSelectStyle{
  justify-content: flex-end;
  display: flex;
  margin-top: 20px;
  height: 50px;
}

.input-position {
  display: flex;
  align-items: flex-start;
  bottom: 3px;
}
</style>

<style>
.fanpage-integration-list .table td{
  vertical-align: middle !important;
}

.fanpage-integration-list .input-position {
  bottom: -1px !important;
}

.fanpage-integration-list .wrap-pages {
  display: none !important;
}
.fanpage-integration-list table>tbody {
  font-weight: 500;
}
.link-action-form:hover {
  text-decoration: none !important;
}

.link-action-form {
  margin-right: 10px !important;
}

.btn-edit,
.btn-history,
.btn-remove {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}

table>tbody {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #81858e;
}

.btn-edit {
  color: var(--bluee);
}

.btn-edit:hover {
  color: var(--bluee);
}

.btn-history:hover {
  color: var(--greenn);
}

.btn-history {
  color: var(--greenn);
}

.btn-remove:hover {
  color: var(--red);
}

.btn-remove {
  color: var(--red);
}

table {
  margin-top: 41px;
}

thead>tr>th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: var(--gray01);



}
</style>
