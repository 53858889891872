<template >
  <div class="row p-4">
    <div class="col-12">
      <img
        class="float-right"
        :src="require('@/assets/icons/close.svg')"
        alt="Voltar"
        @click="$bvModal.hide(idModal)"
        id="icon-close"
      />
    </div>
    <div id="content-title" class="col-12">
      <p id="title-modal" class="m-0">
        Atualizar configuração da Fanpage {{ fanpageName }}
      </p>
      <small
        >E necessário conceder as permissões para buscar os formulário da
        Fanpage</small
      >
    </div>
    <div class="col-12">
      <p class="title-step">Editar configurações</p>
    </div>
    <div class="col-12">
      <LoginFacebook
        @loged="loged"
        :isLoged="false"
        custonClass="btn-fb-edit-configuration"
        title="Editar configurações"
      />
    </div>
  </div>
</template>
<script>
import LoginFacebook from "./LoginFacebook.vue";

export default {
  name: "UpdateConfigurationFanpage",
  props: {
    idModal: {
      required: true,
    },
    fanpageName: {
      required: true,
    },
  },
  components: {
    LoginFacebook,
  },
  methods: {
    loged() {
      this.$emit("loged", event);
    },
  },
};
</script>

<style >
 
.btn-fb-edit-configuration{
  width: 100%
}
.modal-dialog > .modal-md > .modal-dialog-centered {
  width: 443px;
}
.modal-content.shadow {
  border-radius: 7px;
}

.modal-dialog.modal-md.modal-dialog-centered {
  width: 453px;
}

#icon-close {
  cursor: pointer;
}

#title-modal,
.title-step {
  color: #000000;
  align-items: center;
  font-weight: 600;
  font-style: normal;
}

#title-modal {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px !important;
}

.title-step {
  font-size: 14px;
  line-height: 17px;
}
/* 20.82 */
small {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #8c8a97;
}

#content-title {
  margin-bottom: 31.68px;
  margin-top: 15px;
}
</style>
